<template>
  <v-container fluid :style="`margin-left: ${margin}`">
    <div>
      <v-card class="pa-5 pt-3" height="70">
        <v-row>
          <v-col cols="4">
            <v-row>
              <v-col cols="4">
                <v-btn class="mr-2 mt-1" @click="changeWidth()"
                  ><v-icon>mdi-file-pdf-box</v-icon></v-btn
                ><v-btn class="ml-2 mt-1" @click="generateExcel()"
                  ><v-icon>mdi-microsoft-excel</v-icon></v-btn
                ></v-col
              >
              <v-col cols="4" class="mt-1">
                <v-select
                  outlined
                  dense
                  label="Prodajno mjesto"
                  v-model="location"
                  :items="locations"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select
              ></v-col>
              <v-col cols="1"
                ><v-btn
                  :disabled="locationNotSelected"
                  class="mt-1"
                  @click="getData()"
                  ><v-icon>mdi-magnify</v-icon></v-btn
                ></v-col
              >
            </v-row>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="mt-1">
            <v-btn :to="to">Gotovo</v-btn>
          </v-col>
        </v-row>
        <v-row class="pt-0 mt-0 pl-2">
          <div class="d-flex justify-center"></div>
        </v-row>
      </v-card>
    </div>

    <div class="d-flex justify-center">
      <div
        id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
        "
      >
        <table id="firma" style="display: none; font-family: 'Roboto-Regular'">
          <tbody>
            <tr>
              <td>
                {{ company ? $options.filters.capitalize(company.name) : "" }}
              </td>
            </tr>
            <tr>
              <td>{{ company ? company.address + " " + company.city : "" }}</td>
            </tr>
            <tr>
              <td>OIB: {{ company ? company.oib : "" }}</td>
            </tr>
            <tr>
              <td>Prodajno mjesto: {{ location ? location.name : "" }}</td>
            </tr>
          </tbody>
        </table>

        <div style="font-family: font-family: Raleway, sans-serif;">
          <table
            justify="center"
            style="width: 100% !important"
            cellspacing="0"
            id="title"
          >
            <thead>
              <tr class="header">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 25px">Cjenik</p>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div justify="center">
          <table
            justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            bordercolor="gray"
            id="tableTraffic"
          >
            <thead>
              <tr :class="['header', 'naslov']" style="display: none">
                <th colspan="6">
                  <span style="font-weight: bold">Cjenik</span>
                </th>
              </tr>
              <tr :class="['header', 'naslov']">
                <th style="text-align: left">Artikl</th>
                <th style="text-align: right">Količina</th>
                <th style="text-align: right">Cijena</th>
                <th style="text-align: right">Prodajna jedinica</th>
                <th style="text-align: right">Kategorije</th>
                <th style="text-align: right">Datum kreiranja</th>
                <th style="text-align: right">Tip artikla</th>
              </tr>
            </thead>
            <tbody style="width: 100% !important">
              <tr v-for="(item, itIdx) in allItems" :key="item.id + itIdx">
                <td style="text-align: left">{{ item.name }}</td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? (item.stock / 1000).toFixed(3).replace(".", ",")
                      : (item.stock / 1000).toFixed(3)
                  }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters.money(
                          item.prices[currency].price,
                          100,
                          currency
                        )
                      : item.prices[currency].price / 100
                  }}
                </td>
                <td style="text-align: right">{{ item.selling_unit }}</td>
                <td style="text-align: right">
                  {{ item.categoriesFormatted }}
                </td>
                <td style="text-align: right">
                  {{ item.created | local }}
                </td>
                <td style="text-align: right">
                  {{
                    item.type === "GOODS"
                      ? $t("$vuetify.lager.itemTypes.goods")
                      : $t("$vuetify.lager.itemTypes.service")
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>

import { clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import EventBus from '@/plugins/event-bus'
import { initPdf, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { },
  data: function () {
    return {
      allItems: [],
      company: undefined,
      tableWidth: '1200px',
      a4PortraitWidth: '575px',
      a4LandscapeWidth: '610px',
      targetModifier: '',
      viewType: 'pdf',
      itemReport: [],
      totalReport: {},
      currency: 'EUR',
      location: null,
      locations: []
    }
  },
  computed: {
    to () {
      return { name: `${this.targetModifier}.all-reports.reportMenu` }
    },
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    locationNotSelected () {
      return !this.location || !this.location.id
    }
  },
  async created () {
    this.margin = state.getMargin()
    await this.getPointsOfSale()
  },
  mounted () {
    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.company = clone(state.getCurrentCompany())
    this.location = clone(state.getPointOfSale())
    this.getData()
  },
  methods: {
    async getData () {
      var tempItem = []
      this.allItems = []

      const posId = this.location !== null ? this.location.id : state.getPointOfSale().id

      await df.collection(`location_pricelist/${posId}/items`)
        .where('active', '==', true)
        .where('status', '==', 'OK')
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              tempItem.push(item.data())
            })
          }
        })

      tempItem.sort((a, b) => a.name - b.name) // b - a for reverse sort

      tempItem.forEach((item, idx) => {
        if (item.warehouse_units && item.warehouse_units.base_unit) {
          var whUnits = clone(item.warehouse_units)
          Object.keys(whUnits.units).forEach(key => {
            if (whUnits.units[key].is_selling_item === true) {
              var tempItem = clone(item)
              tempItem.name = tempItem.name + ' ' + whUnits.units[key].storage_unit
              tempItem.prices = whUnits.units[key].prices
              tempItem.storage_unit = whUnits.units[key].storage_unit
              tempItem.selling_unit = whUnits.units[key].storage_unit
              tempItem.itemType = 'warehouse'
              tempItem.categoriesFormatted = ''

              item.categories.forEach((cat, cidx) => {
                if (cidx === 0) {
                  tempItem.categoriesFormatted = cat.name
                } else {
                  tempItem.categoriesFormatted = tempItem.categoriesFormatted + ', ' + cat.name
                }
              })

              if (tempItem.prices && tempItem.prices[this.currency] && tempItem.prices[this.currency].price) {
                this.allItems.push(tempItem)
              }
            }
          })
        } else {
          var tempItem = clone(item)
          tempItem.itemType = 'simple'
          tempItem.categoriesFormatted = ''
          item.categories.forEach((cat, cidx) => {
            if (cidx === 0) {
              tempItem.categoriesFormatted = cat.name
            } else {
              tempItem.categoriesFormatted = tempItem.categoriesFormatted + ', ' + cat.name
            }
          })

          this.allItems.push(tempItem)
        }
      })

      this.allItems.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    },
    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()

      doc = await setTable(doc, '#firma', 10)
      var finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#title', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tableTraffic', finalY)

      doc = await setPageNumber(doc)

      doc.save('Cjenik.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['firma', 'title', 'tableTraffic']
        var fileName = 'Cjenik.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
    }
  }
}
</script>
<style scoped>
#tableTraffic {
  border: 1px solid #767676;
}

#tableTax {
  border: 1px solid #767676;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}
</style>
